import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import StyledFieldSet from './FieldSet.style';
import SlideUp from './SlideUp';

window.fieldSetCounter = window.fieldSetCounter || 0;

const FieldSet = ({
  label,
  info,
  error,
  touched,
  children,
  tag = 'label',
  placeholder,
  autocomplete,
  onChange,
  value,
  onBlur,
  onInputIdChanged = null,
  readonly,
  inputId: _inputId = null,
  ...p
}) => {
  const [errorStr, setErrorStr] = useState('');
  useEffect(() => {
    if (!error) return;
    setErrorStr(error);
  }, [error]);

  // generate a unique label id so form fields can link to the label
  const inputId = useMemo(() => {
    return _inputId || `fieldset-input-${window.fieldSetCounter++}`;
  }, [_inputId]);
  useEffect(() => {
    onInputIdChanged?.(inputId);
  }, [onInputIdChanged, inputId]);

  return (
    <StyledFieldSet as={tag} {...p}>
      <div className="input-field">
        {!label ? undefined : typeof label === 'string' ? (
          <label htmlFor={inputId} className="label input-label">
            {label}
          </label>
        ) : typeof label === 'function' ? (
          label({ id: inputId })
        ) : (
          label
        )}
        {!info ? undefined : typeof info === 'string' ? (
          <span className="info">{info}</span>
        ) : typeof info === 'function' ? (
          info()
        ) : (
          info
        )}
        {typeof children === 'function'
          ? children({
              placeholder,
              autocomplete,
              onChange,
              value,
              onBlur,
              hasError: !!error,
              touched: touched,
              id: inputId,
              disabled: readonly,
            })
          : children}
      </div>
      <SlideUp className="error slide-down" open={!!error}>
        {typeof errorStr === 'string' ? <span>{errorStr}</span> : errorStr}
      </SlideUp>
    </StyledFieldSet>
  );
};
export default FieldSet;
