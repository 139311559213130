import React from 'react';
import styled from '@emotion/styled';

// eslint-disable-next-line react/display-name
const Component = React.forwardRef(({ as: T = 'label', ...props }, ref) => (
  <T {...props} ref={ref} />
));
export default styled(Component)`
  &.required {
    .label.input-label::after {
      ${({ theme }) => theme.typography.label}
      color: ${({ theme }) => theme.colors.pacificBlue};
      content: '*';
      margin-left: 6px;
    }
  }

  .input-field {
    & > .label {
      ${({ theme }) => theme.typography.label}
    }

    & > .info {
      display: block;
      margin: -5px 0 5px;
      ${({ theme }) => theme.typography.labelInfo}
    }

    & > .error {
      ${({ theme }) => theme.typography.formError}
      color: ${({ theme }) => theme.colors.error};
    }
  }

  // Could be improved to target any kind of form input
  input {
    color: ${({ theme }) => theme.colors.trout};
  }
`;
