import React from 'react';
import StyledFancySelectField from './FancySelectField.style';

/**
 *
 * @param {React.ComponentProps<Select>} props
 * @returns
 */
const FancySelectField = ({ ...p }) => {
  return <StyledFancySelectField {...p} />;
};
export default FancySelectField;
