import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { css, useTheme } from '@emotion/react';
import { lighten } from 'polished';
import { fixIOSZoom } from './TextField.style';

const StyledSelect = styled(Select)`
  input[role='combobox'] {
    ${fixIOSZoom}
  }
`;
const StyledAsyncSelect = styled(AsyncSelect)`
  input[role='combobox'] {
    ${fixIOSZoom}
  }
`;

const StyledIndicator = styled.svg`
  transition: fill 150ms linear;
  margin: 0 18px 0 0;
`;

const getScrollParent = (node) => {
  const parent = node?.parentNode;
  if (!parent) {
    return null;
  }
  if (parent.scrollHeight > parent.clientHeight) {
    return parent;
  } else {
    return getScrollParent(parent);
  }
};
/**
 * StyledFancySelectField is a custom select field component that supports both synchronous and asynchronous options.
 * It utilizes styled components to apply custom styles based on the theme and error state.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.hasError - Indicates if the select field should be styled with an error state.
 * @param {boolean} props.async - Determines if the select field should use asynchronous loading for options.
 *
 * @returns {JSX.Element} A styled select field component.
 */
const StyledFancySelectField = ({ hasError, async, ...p }) => {
  const theme = useTheme();
  const ref = React.useRef();
  /** @type Select */
  const Component = async ? StyledAsyncSelect : StyledSelect;

  const onMenuOpen = useCallback(() => {
    // get the scroll parent, needs to be done before the menu is shown
    const controlElem = ref.current.controlRef;
    const scrollParent = getScrollParent(controlElem?.parentNode);
    setTimeout(() => {
      const menuListElem = ref.current.menuListRef;
      if (!controlElem || !menuListElem || !scrollParent) return;
      const menuListRect = menuListElem.getBoundingClientRect();
      const parentRect = scrollParent.getBoundingClientRect();
      if (menuListRect.bottom > parentRect.bottom) {
        const scrollPos = scrollParent.scrollTop;
        const scrollBy = menuListRect.bottom - parentRect.bottom + 10;
        scrollParent.scrollTo({
          top: scrollPos + scrollBy,
          behavior: 'smooth',
        });
      }
    }, 100);
  }, [ref.current]);

  return (
    <Component
      noOptionsMessage={
        async
          ? ({ inputValue }) =>
              inputValue ? 'Geen opties gevonden' : 'Typ om te zoeken'
          : () => 'Geen opties'
      }
      {...p}
      ref={ref}
      onMenuOpen={onMenuOpen}
      styles={{
        clearIndicator: (base) => ({ ...base }),
        container: (base) => ({ ...base }),
        control: (base, { isFocused }) => {
          return {
            label: 'control',
            alignItems: 'center',
            borderRadius: 4,
            borderStyle: 'solid',
            borderWidth: 1,
            cursor: 'default',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            minHeight: 48,
            outline: '0 !important',
            position: 'relative',
            transition: 'all 150ms linear',
            boxSizing: 'border-box',
            backgroundColor: theme.colors.white,
            borderColor: hasError
              ? theme.colors.error
              : isFocused
                ? theme.colors.trout
                : theme.colors.quartz,
          };
        },
        group: (base) => ({ ...base }),
        groupHeading: (base) => ({ ...base }),
        input: (base) => ({ ...base }),
        loadingIndicator: (base) => ({ ...base }),
        loadingMessage: (base) => ({ ...base }),
        menu: (base) => css`
          ${base}
          background-color: ${({ theme }) => theme.colors.white};
          .small {
            font-size: 0.8em;
          }
        `,
        menuList: (base) => ({ ...base, backgroundColor: theme.colors.white }),
        menuPortal: (base) => ({ ...base }),
        multiValue: (base) => ({ ...base }),
        multiValueLabel: (base) => ({ ...base }),
        multiValueRemove: (base) => ({ ...base }),
        noOptionsMessage: (base) => ({ ...base }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
                ? theme.colors.pacificBlue
                : isFocused
                  ? theme.colors.aliceBlue
                  : undefined,
            color: isDisabled
              ? theme.colors.dimGray
              : isSelected
                ? theme.colors.trout
                : theme.colors.trout,
          };
        },
        placeholder: (base) => {
          return { ...base, color: theme.colors.dimGray };
        },
        singleValue: (base) => ({ ...base, color: theme.colors.trout }),
        valueContainer: (base) => ({ ...base }),
      }}
      theme={{
        borderRadius: 4,
        colors: {
          primary: theme.colors.pacificBlue,
          primary75: lighten(0.2, theme.colors.pacificBlue),
          primary50: lighten(0.4, theme.colors.pacificBlue),
          primary25: lighten(0.6, theme.colors.pacificBlue),
          danger: theme.colors.error,
          dangerLight: '#FFBDAD',
          ...theme.colors.selectNeutrals,
        },
        spacing: {
          baseUnit: 4,
          controlHeight: 48,
          menuGutter: 8,
        },
      }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => {
          return (
            <StyledIndicator
              fill={theme.colors.trout}
              height="4"
              width="8"
              viewBox="0 0 8 4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0l4 4 4-4z" />
            </StyledIndicator>
          );
        },
      }}
    />
  );
};
export default StyledFancySelectField;
